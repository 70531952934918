import "../style.less";
import React from "react";
import { FlatIconProps } from "../types";

export const SVGGithub: React.FC<FlatIconProps> = ({ active, className = "", ...restProps }) => {
    return (
        <svg
            className={`${className} flat-icon ${active ? "is-active" : ""}`}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                className="flat-icon-fill-color"
                d="m5 7 .593.198A.625.625 0 0 0 5.625 7H5Zm0-3 .198-.593A.625.625 0 0 0 4.375 4H5Zm14 0h.625a.625.625 0 0 0-.823-.593L19 4Zm0 3h-.625c0 .067.01.134.032.198L19 7Zm-2.693 7.673.152.607-.152-.607Zm3.267-5.951-.593.198.593-.198ZM7.693 14.673l-.152.607.152-.607ZM8.625 20v-2.586h-1.25V20h1.25Zm.527-5.606-1.307-.327-.304 1.213 1.307.326.304-1.212ZM5.019 8.92l.574-1.722-1.186-.396-.574 1.722 1.186.396ZM5.625 7V4h-1.25v3h1.25Zm-.823-2.407 3 1 .396-1.186-3-1-.396 1.186Zm11.396 1 3-1-.396-1.186-3 1 .396 1.186ZM18.375 4v3h1.25V4h-1.25Zm.032 3.198.574 1.722 1.186-.396-.574-1.722-1.186.396Zm-2.252 6.869-1.307.327.304 1.212 1.307-.326-.304-1.213Zm-.78 3.347V20h1.25v-2.586h-1.25Zm3.692-6.259a4.001 4.001 0 0 1-2.912 2.912l.304 1.213a5.252 5.252 0 0 0 3.82-3.821l-1.212-.304Zm-10.442 6.26c0-.74.294-1.45.817-1.973l-.884-.884a4.039 4.039 0 0 0-1.183 2.856h1.25ZM18.981 8.92a4 4 0 0 1 .086 2.235l1.213.304a5.252 5.252 0 0 0-.113-2.935l-1.186.396Zm-4.423 6.522a2.79 2.79 0 0 1 .817 1.972h1.25a4.04 4.04 0 0 0-1.183-2.856l-.884.884Zm-9.625-4.287A4.001 4.001 0 0 1 5.02 8.92l-1.186-.396a5.252 5.252 0 0 0-.113 2.935l1.213-.304ZM8.28 5.56a8.32 8.32 0 0 1 7.44 0l.56-1.118a9.57 9.57 0 0 0-8.56 0l.56 1.118Zm-.435 8.508a4.001 4.001 0 0 1-2.912-2.912l-1.213.304a5.252 5.252 0 0 0 3.821 3.82l.304-1.212ZM5 15.375H4v1.25h1v-1.25Zm2 2c-.76 0-1.375-.616-1.375-1.375h-1.25A2.625 2.625 0 0 0 7 18.625v-1.25Z"
                fill="#5D6066"
            ></path>
        </svg>
    );
};

export default SVGGithub;
